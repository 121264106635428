new Vue({
  el : '#circle',
  data:{
    show:false
  },
  mounted (){
    setInterval(function(){
      $(".cc-1").toggleClass("c-1");
      $(".cc-2").toggleClass("c-2");
      $(".cc-3").toggleClass("c-3");
    },4000)
  },
  methods :{
    addClass(){
      $nextTick(function(){
        $('.dialog-content').addClass('fadeInRight')
      })
    },
    removeClass(){
      $nextTick(function(){
        $('.dialog-content').removeClass('fadeInRight')
      })
    }
  }
})